<template>
  <b-card>
    <div class="d-flex">
      <b-form-group class="mr-2 mw-100 w-25 mb-2 mr-2" label="Nome do Evento" label-for="search">
        <b-input-group class="input-group-merge">
          <b-form-input placeholder="Buscar por nome do evento" v-model="search" />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group class="mr-2 mw-100 w-25 mb-2 mr-2" label="Status" label-for="statusObrigacoesOpcoes">
        <v-select class="status-obrigacoes" placeholder="Status" v-model="statusObrigacoes" :options="statusObrigacoesOpcoes" :clearable="true" />
      </b-form-group>
      <b-form-group class="mr-2 mw-100 w-25 mb-2"  label="Data de Vencimento" label-for="obrigacoesVencimento">
        <div class="input-icon">
          <flat-pickr
            v-model="obrigacoesVencimento"
            class="form-control date-picker"
            :config="{ dateFormat: 'd/m/Y', mode: 'range'}"
            placaeholder="Data de vencimento"
          />
          <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
        </div>
      </b-form-group>
    </div>
    <b-table
      responsive="sm"
      :items="eventos"
      :fields="fields"
      class="mb-0 pus-table"
      ref="table"
    >
      <template #cell(emissao)="data">
        <a 
          @click="$router.push({ name: 'investor.details', params: { serie_id: data.item.emissao_serie } })"
          class="text-secondary font-weight-bold"
        >
          {{ data.item.emissao_nome }}
        </a>
      </template>
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${variantBagdeStatusItemEventos(data.item.status)}`"
          :class="`border-${variantBagdeStatusItemEventos(data.item.status)}`"
          pill
        >
          {{ variantNameStatusItemEventos(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(observacao_instancia)="data">
        {{ data.item.observacao_instancia }}
      </template>

      <template #cell(url)="data">
        <a v-if="data.item?.link_anexo" :href="data.item.link_anexo" target="_blank">
          <feather-icon icon="DownloadIcon" size="22" class="text-success" />
        </a>
        <span :id="`span-${data.item.id}`" v-else class="text-center">
          <feather-icon icon="AlertCircleIcon" size="20" class="mr-50" />
          <b-tooltip
            :ref="`tooltip-${data.item.id}`"
            triggers="hover"
            :target="`span-${data.item.id}`"
            title="Este evento não possui link para download."
            variant="primary"
            :delay="{ show: 100, hide: 10 }"
          />
        </span>
      </template>
    </b-table>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center mt-1">
        <b-button
          variant="primary"
          class="mr-2"
          @click="exportarExcel"
          :disabled="!eventos"
        >
          Exportar para Excel
          <img class="img-icon" height="17" width="16" src="@/assets/images/export_icon.svg" />
        </b-button>
      </div>
      <div class="align-items-center mt-1 left-auto float-right mb-1 d-flex" v-if="eventos?.length > 0">
        <v-select class="mr-3" v-model="perPage" :options="perPageOptions" :clearable="false" />
        <span v-show="totalItems" class="mr-3 mb-0 h5 mr-2"> Exibindo {{eventos.length}} de {{ totalItems }} </span>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="1"
          first-number
          last-number
          class="mb-0"
          @change="changePage"
          ref="pagination"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BTable,
  BBadge,
  BFormInput,
  BFormGroup,
  BButton,
  BButtonGroup,
  BTooltip,
  BPagination,
  BInputGroupAppend,
  BInputGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import http from '@/services/http'
import { moneyFormat } from '@/@core/comp-functions/data_visualization/datatable'
import { formatDate } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { downloadFromResponse } from '@/@core/comp-functions/forms/cc-dropzone'

export default {
  name: 'Despesas',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BTable,
    BBadge,
    BFormInput,
    BFormGroup,
    BButton,
    BButtonGroup,
    BTooltip,
    BPagination,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      eventos: [],
      totalItems: 0,
      totalRows: 0,
      fields: [
        {
          key: 'emissao',
          label: 'Emissão',
        },
        {
          key: 'resumo',
          label: 'Evento',
        },

        {
          key: 'data_vencimento',
          label: 'Vencimento',
          formatter: (value) => formatDate(value) || '-',
        },
        {
          key: 'data_baixa',
          label: 'Conclusão',
          formatter: (value) => formatDate(value) || '-',
        },
        {
          key: 'observacao_instancia',
          label: 'Observação',
        },
        {
          key: 'status',
          label: 'Status',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'url',
          label: 'Download',
        },
      ],
      currentPage: 1,
      perPage: 10, 
      perPageOptions: [10, 25, 50],
      search: '', 
      statusObrigacoes: '',
      obrigacoesVencimento: '',
      statusObrigacoesOpcoes: ['Completo', 'Atrasado', 'Pendente'],
    }
  },
  mounted() {
    this.getEvents()
  },
  methods: {
    filterParams () {
      const [initialDate, finalDate] = this.obrigacoesVencimento.split(' to ')

      return {
        page: this.currentPage, 
        per_page: this.perPage, 
        search: this.search, 
        status: this.statusObrigacoes,
        data_vencimento: (initialDate || null),
        data_vencimento_final: (finalDate || null)
      }
    },
    async getEvents() {
      
      const event = await http.get('investidor/eventos', {
        params: this.filterParams()
      })

      this.totalRows = event.data.total_pages
      this.eventos = event.data.data
      this.totalItems = event.data.total_items
    },
    async changePage() {
    if(this.$refs.pagination !== undefined) {
      this.currentPage = this.$refs.pagination.currentPage
    } else {
      this.currentPage = 1
    }
    await this.getEvents()
    },
    formatDate,
    variantBagdeStatusItemEventos(status) {
      const variant = {
        Pendente: 'warning',
        Concluído: 'success',
        Atrasado: 'danger',
        Completo: 'success',
      }
      return variant[status]
    },
    variantNameStatusItemEventos(status) {
      const variant = {
        Pendente: 'A vencer',
        Concluído: 'Concluído',
        Atrasado: 'Atrasado',
        Completo: 'Completo',
      }
      return variant[status]
    },
    async exportarExcel(){
      this.$swal({
        title: 'Carregando dados...',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
      })
      try {
        const { data } = await http.get('investidor/eventos/exportar_xlsx', {
          params: this.filterParams(),
          responseType: 'blob',
        })
        downloadFromResponse(data, {
          mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileName: 'eventos.xlsx',
        })
        this.$swal.close()
      } catch (error) {
        const reader = new FileReader()
        reader.onload = () => {
          const errorText = JSON.parse(reader.result).errors.join(' ')
          this.$swal.fire({
            title: 'Erro!',
            text: errorText,
            icon: 'error',
          })
        }
        reader.readAsText(error.response.data)
      }
    }
  },
  watch: {
    perPage: function (newVal) {
      this.changePage();
    },
    statusObrigacoes: function (newVal) {
      this.changePage();
    },
    search: function(newVal) {
      this.changePage();
    },
    obrigacoesVencimento: function(newVal) {
      this.changePage();
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.input-icon {
  position: relative;
  display: inline-block;
}

.icon-position {
  position: absolute;
  right: 10px;
  top: 8px;
}
</style>